<template>
  <v-app 
  fixed-tabs 
  style="background: #E3F2FD;background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);"
  >
    <!-- class="bgregister" style="background: #E3F2FD;
        background: linear-gradient(to bottom, #1976D2, #1E88E5, #E3F2FD);" -->
    <section
    >
      <!-- :src="require('../assets/img/background_register_full.png')" -->
      <!-- <v-img
        class="white--text"
      > -->
        <div>
          <v-container>
            <v-layout>
              <img
                style="margin-bottom:10px;"
                :src="
                  require('@/assets/img/logo_onebox-horizontal-textwhite.png')
                "
                width="140px"
              />
            </v-layout>
            <v-layout class="text-white">
              <v-chip
                v-if="$t('default') === 'th'"
                class="ml-0 mt-0"
                @click="$router.push('/')"
                style="cursor:pointer;"
                >{{ "กลับหน้าหลัก" }}</v-chip
              >
              <v-chip
                v-else
                class="ml-0 mt-0"
                @click="$router.push('/')"
                style="cursor:pointer;"
                >{{ "HOME" }}</v-chip
              >
            </v-layout>
          </v-container>
        </div>
          <v-container>
        <v-layout justify-center>
             <v-btn-toggle
                        v-model="type_package"
                        :color="type_package ? '#2D4E9A' : 'grey lighten-1'"
                        borderless
                        mandatory
                      >
                        <v-btn value="personal">
                          <span>{{ $t("packagenew.personal") }}</span>
                        </v-btn>
                        <v-btn value="business">
                          <span>{{ $t("packagenew.business") }}</span>
                        </v-btn>
              </v-btn-toggle>
        </v-layout>
          </v-container>
        <!-- หน้าจอปกติ -->
        <!-- ผู้ใช้งานทั่วไป -->
        <div v-if="type_package === 'personal' ">
        <v-layout row wrap align-center justify-center v-if="resolutionScreen>400">
          <v-flex lg9 xs11 class="ma-0">
            <v-card
              color="white"
              style="border-radius: 20px;"
            >
              <v-card-text class="pa-0">
                <v-form v-model="valid" class="ma-4 mt-4 mr-8 ml-8">
                  <v-container>
                    <v-layout row wrap justify-center>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-layout>
                          <v-card-text style="font-size: 18px">
                            <v-layout justify-center class="mt-0">
                              <div
                                style="margin-top:6px;margin-right:30px; color:#333333"
                              >
                              <h2 class="text-center mt-10 mb-1" :style="darkModeText">
                                <!-- คำนวนค่าบริการ Storage ของคุณ -->
                                {{ $t("packagenew.service_charge") }} 
                              </h2>
                              </div>
                            </v-layout>
                            <v-layout justify-end style="margin-top:-45px;">
                              <v-spacer></v-spacer>
                              <!-- ภาษา -->
                              <v-menu
                                offset-y
                                bottom
                                open-on-hover
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    class=" white--text mr-2 font-weight-bold"
                                  >
                                    <flag :iso="defaultflag" :squared="false" />
                                  </v-btn>
                                </template>
                                <v-list nav dense>
                                  <v-list-item
                                    v-for="(item, i) in language"
                                    :key="i"
                                    @click="changelang(item.data)"
                                  >
                                    <v-list-item-action>
                                      <flag :iso="item.flag" :squared="false" />
                                    </v-list-item-action>
                                    <v-list-item-title>{{
                                      $t(item.name)
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-layout>
                          </v-card-text>
                        </v-layout>
                        <!-- <v-divider></v-divider> -->
                      </v-flex>
                    </v-layout>
                    <!-- คำนวน storage -->
              <v-layout row wrap justify-center>
                <v-flex lg8 xs11 class="text-center">
                  <v-row class="text-center">
                    <v-col cols="12" class="py-2">
                      <v-btn-toggle
                        v-model="packageTypeSelected"
                        :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                        borderless
                        mandatory
                      >
                        <v-btn value="M">
                          <span>{{ $t("packagenew.month") }}</span>
                        </v-btn>
                        <v-btn value="Y">
                          <span>{{ $t("packagenew.year") }}</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-card class="mx-auto mt-8" outlined>
                    <br/>
                    <v-row>
                      <v-col cols="6">
                        <v-layout row wrap justify-center>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0 mt-6"
                              fab
                              large
                              color="success"
                              :disabled="storage_citizen <= 10"
                              @click.prevent="fn_minus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-text-field
                              outlined
                              class="ml-2 mt-6 text-center"
                              v-model="storage_citizen"
                              suffix="GB"
                              @input="fn_cal_price()"
                              readonly
                            ></v-text-field>
                               <!-- :rules="requiredstorage_citizen"
                              required
                              :error-messages="storage_citizenError"
                              @input="fn_cal_price(),$v.storage_citizen.$touch()"
                              @blur="$v.storage_citizen.$touch()" -->
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0  mt-6"
                              fab
                              large
                              color="success"
                              @click.prevent="fn_plus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-col>
                   
                      <v-divider vertical></v-divider>
                      <v-col cols="5" class="ml-6">
                        <div class="calculated-price tk-prometo">
                          <span class="currency-label text-h3 text--primary" > ฿ </span>
                          <span id="priceLabelPerMonth" class="text-h3 text--primary"> {{ packageTypeSelected === 'M' ? numberWithCommas(price_storage_citizen) : numberWithCommas(price_storage_citizen *12)}}</span>
                        </div>
                        <div>
                          <span class="currency-label" v-if="packageTypeSelected === 'M'"> / {{ $t("my_package.month") }}</span>
                          <span class="currency-label" v-else> / {{ $t("my_package.year") }}</span>
                        </div>
                        <div class="my-2">
                          <v-btn large :color="color.theme" dark @click="$router.push('/')">
                           {{ $t("packagenew.login_for_purchase_storage") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <br/>
                  </v-card>
                <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="text-right font-weight-regular mt-2" style="color:red;">
                  {{ $t("packagenew.text_notvat") }}
                </p>
                <br />
              </v-flex>
                </v-flex>
              </v-layout>
                    <!-- <v-divider></v-divider> -->
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <!-- หน้าจอมือถือ -->
        <!-- ผู้ใช้งานทั่วไป -->
        <v-layout row wrap align-center justify-center v-else>
          <v-flex lg9 xs11 class="ma-0">
            <v-card
              color="white"
              style="border-radius: 20px;"
            >
              <v-card-text class="pa-0">
                <v-form v-model="valid" class="ma-4 mt-4 mr-8 ml-8">
                  <v-container>
                    <v-layout row wrap justify-center>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-layout>
                          <v-card-text style="font-size: 18px">
                            <v-layout justify-center class="mt-n5">
                              <div
                                style="margin-top:6px;margin-right:30px; color:#333333"
                              >
                              <h3 class="text-center mt-10 mb-1" style="font-size:18px;">
                                <!-- คำนวนค่าบริการ Storage ของคุณ -->
                                {{ $t("packagenew.service_charge") }} 
                              </h3>
                              </div>
                            </v-layout>
                            <v-layout justify-end style="margin-top:-33px;margin-right:-45px;">
                              <v-spacer></v-spacer>
                              <!-- ภาษา -->
                              <v-menu
                                offset-y
                                bottom
                                open-on-hover
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    class=" white--text mr-2 font-weight-bold"
                                  >
                                    <flag :iso="defaultflag" :squared="false" />
                                  </v-btn>
                                </template>
                                <v-list nav dense>
                                  <v-list-item
                                    v-for="(item, i) in language"
                                    :key="i"
                                    @click="changelang(item.data)"
                                  >
                                    <v-list-item-action>
                                      <flag :iso="item.flag" :squared="false" />
                                    </v-list-item-action>
                                    <v-list-item-title>{{
                                      $t(item.name)
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-layout>
                          </v-card-text>
                        </v-layout>
                        <!-- <v-divider></v-divider> -->
                      </v-flex>
                    </v-layout>
                    <br />
              <!-- คำนวน storage -->
              <v-layout row wrap justify-center>
                <v-flex lg8 xs12 class="text-center">                
                  <v-card class="mx-auto pa-4" outlined id="borderradius">
                    <v-tabs
                      v-model="packageTypeSelected"
                      :color="color.theme"
                      left
                      class="mb-4"
                    >
                      <v-tab href="#M">{{ $t("packagenew.month") }}</v-tab>
                      <v-tab href="#Y">{{ $t("packagenew.year") }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="packageTypeSelected">
                      <v-tab-item
                        v-for="i in ['M','Y']"
                        :key="i"
                        :value="i"
                      >
                      </v-tab-item>
                    </v-tabs-items>
                    <v-row>
                      <v-col cols="12">
                        <v-layout row wrap justify-center>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0"
                              fab
                              small                              
                              color="success"
                              :disabled="storage_citizen <= 10"
                              @click.prevent="fn_minus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-text-field
                              outlined
                              hide-details
                              dense
                              class="ml-2 mr-2 text-center"
                              v-model="storage_citizen"
                              suffix="GB"
                              @input="fn_cal_price()"
                              readonly
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0"
                              fab
                              small
                              color="success"
                              @click.prevent="fn_plus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12">
                        <div class="calculated-price tk-prometo">
                          <span class="currency-label text-h4 text--primary" > ฿ </span>
                          <span id="priceLabelPerMonth" class="text-h4 text--primary"> 
                            {{ packageTypeSelected === 'M' ? numberWithCommas(price_storage_citizen) : numberWithCommas(price_storage_citizen *12)}}
                          </span>
                          <span class="currency-label" v-if="packageTypeSelected === 'M'"> / {{ $t("my_package.month") }}</span>
                          <span class="currency-label" v-else> / {{ $t("my_package.year") }}</span>
                        </div>
                        <div class="mt-4">
                          <v-btn small :color="color.theme" dark @click="$router.push('/')">
                           {{ $t("packagenew.login_for_purchase_storage") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-flex lg12 xs12 class="mr-4 ml-4">
                  <p class="text-left font-weight-regular mt-2" style="color:red;">
                    {{ $t("packagenew.text_notvat") }}
                  </p>
                  </v-flex>
                </v-flex>
              </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        </div>
        <!--ธุรกิจ Business-->
        <div v-else>
          <!-- หน้าจอปกติ -->
          <v-layout row wrap align-center justify-center v-if="resolutionScreen>400">
          <v-flex lg9 xs11 class="ma-0">
            <v-card
              color="white"
              style="border-radius: 20px;"
            >
              <v-card-text class="pa-0">
                <v-form v-model="valid" class="ma-4 mt-4 mr-8 ml-8">
                  <v-container>
                    <v-layout row wrap justify-center>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-layout>
                          <v-card-text style="font-size: 18px">
                            <v-layout justify-center class="mt-0">
                              <div
                                style="margin-top:6px;margin-right:30px; color:#333333"
                              >
                              <h2 class="text-center mt-10 mb-1" :style="darkModeText">
                                <!-- เลือกแพ็กเกจ Onebox ที่เหมาะกับคุณ แพ็กเกจสมาชิกเริ่มต้นที่ 350 บาท/เดือน -->
                                {{ $t("packagenew.title_package") }}
                              </h2>
                              </div>
                            </v-layout>
                            <v-layout justify-end style="margin-top:-45px;">
                              <v-spacer></v-spacer>
                              <!-- ภาษา -->
                              <v-menu
                                offset-y
                                bottom
                                open-on-hover
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    class=" white--text mr-2 font-weight-bold"
                                  >
                                    <flag :iso="defaultflag" :squared="false" />
                                  </v-btn>
                                </template>
                                <v-list nav dense>
                                  <v-list-item
                                    v-for="(item, i) in language"
                                    :key="i"
                                    @click="changelang(item.data)"
                                  >
                                    <v-list-item-action>
                                      <flag :iso="item.flag" :squared="false" />
                                    </v-list-item-action>
                                    <v-list-item-title>{{
                                      $t(item.name)
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-layout>
                          </v-card-text>
                        </v-layout>
                        <!-- <v-divider></v-divider> -->
                      </v-flex>
                    </v-layout>
                    <br />
                <v-row class="text-center">
                  <v-col cols="12" class="py-2">
                    <v-btn-toggle
                      v-model="packageTypeSelected"
                      :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                      borderless
                      mandatory
                      @change="getdatapackage(packageTypeSelected)"
                    >
                      <v-btn value="M">
                        <span>{{ $t("packagenew.month") }}</span>
                      </v-btn>
                      <v-btn value="Y">
                        <span>{{ $t("packagenew.year") }}</span>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <!-- package -->
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-sheet class="mx-auto mr-4 ml-4" elevation="0" id="borderradius_card">
                  <v-slide-group v-model="model" class="pa-4" center-active show-arrows>
                    <v-slide-item
                      active-class="primary"
                      v-for="(item, text) in listpackage"
                      :key="text"
                      class="ma-1"
                      v-slot="{ active, toggle }"
                    >
                      <v-card
                        height="570"
                        width="300"
                        outlined
                        style="height: 570px;"
                        hover
                        :color="active ? 'primary' : 'lighten-1'"
                        :dark="active ? true : false"
                      >
                        <v-layout justify-left class="pt-2">
                          <v-card-title :style="active ? price_dark : $vuetify.theme.dark ? price_dark : price_">{{ numberWithCommas(item.package_price) }}</v-card-title>
                          <p class="text-center mt-6" style="fornt-size:25" v-if="packageTypeSelected === 'M'">{{ $t("packagenew.baht_month") }}</p>
                          <p class="text-center mt-6" style="fornt-size:25" v-else>{{ $t("packagenew.baht_year") }}</p>
                        </v-layout>
                        <v-layout justify-left>
                          <h2 class="text-center mt-4 ml-4 " style="fornt-size:24">{{ item.package_name }}</h2>
                        </v-layout>
                        <v-layout justify-left>
                          <h2
                            class="text-center mt-4 ml-4 "
                            :style="active ? 'fornt-size:24; color: #FFFFFF;' : 'fornt-size:24; color: #1279BC; '"
                          >
                            {{ item.package_storage }} 
                          </h2>
                        </v-layout>
                        <br />
                        <v-layout justify-left>
                          <v-card class="mx-auto" max-width="344" elevation="0">
                            <v-list :color="active ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" class="#FFFFFF" :dark="active ? true : false">
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Manage User</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Upload/Download/Share</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Restrict Access</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Advance Search</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>Connect Through API</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item dense>
                                <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title> Send File to e-Mail</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-layout>
                        <br />
                        <v-flex lg12 xs12 class="mr-4 ml-4">
                        </v-flex>
                        <v-flex lg12 xs12 class="mr-4 ml-4 ma-4">
                          <div class="text-center" @click="toggle">
                            <v-btn rounded color="#5AB685" dark @click="$router.push('/')">
                            <!-- {{active === true ? 'แพ็กเกจปัจจุบัน' : 'เลือกแพ็กเกจ'}}   -->{{ $t("packagenew.login_for_purchase_package") }}
                            </v-btn>
                          </div>
                        </v-flex>
                      </v-card>
                    </v-slide-item>
                  </v-slide-group>
               
                </v-sheet>
              </v-flex>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="font-weight-regular mt-2" style="color:red;">
                  {{ $t("packagenew.vat") }}
                </p>
                <br />
                <v-divider></v-divider>
              </v-flex>
                    <!-- <v-divider></v-divider> -->
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
          </v-layout>
          <!-- หน้าจอมือถือ -->
          <v-layout row wrap align-center justify-center v-else>
          <v-flex lg9 xs11 class="ma-0">
            <v-card
              color="white"
              style="border-radius: 20px;"
            >
              <v-card-text class="pa-0">
                <v-form v-model="valid" class="ma-4 mt-4 mr-6 ml-6">
                  <v-container>
                    <v-layout row wrap justify-center>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-layout>
                          <v-card-text style="font-size: 18px">
                            <v-layout justify-center class="mt-n5">
                              <div
                                style="margin-top:6px;margin-right:30px; color:#333333"
                              >
                              <!-- เลือกแพ็กเกจ Onebox ที่เหมาะกับคุณ แพ็กเกจสมาชิกเริ่มต้นที่ 350 บาท/เดือน -->
                              <h3 class="text-center mt-10 mb-1" style="font-size:18px;">
                                {{ $t("packagenew.title_package") }}
                              </h3>
                              </div>
                            </v-layout>
                            <v-layout justify-end style="margin-top:-53px;margin-right:-45px;">
                              <v-spacer></v-spacer>
                              <!-- ภาษา -->
                              <v-menu
                                offset-y
                                bottom
                                open-on-hover
                                transition="slide-y-transition"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    class=" white--text mr-2 font-weight-bold"
                                  >
                                    <flag :iso="defaultflag" :squared="false" />
                                  </v-btn>
                                </template>
                                <v-list nav dense>
                                  <v-list-item
                                    v-for="(item, i) in language"
                                    :key="i"
                                    @click="changelang(item.data)"
                                  >
                                    <v-list-item-action>
                                      <flag :iso="item.flag" :squared="false" />
                                    </v-list-item-action>
                                    <v-list-item-title>{{
                                      $t(item.name)
                                    }}</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </v-layout>
                          </v-card-text>
                        </v-layout>
                        <!-- <v-divider></v-divider> -->
                      </v-flex>
                    </v-layout>
                    <br />
                    <!-- package [new] -->
              <v-flex lg12 xs12 class="mx-0">
                <v-sheet elevation="0" id="borderradius" class="pa-0" outlined>                  
                  <v-tabs
                    v-model="packageTypeSelected"
                    :color="color.theme"
                    left
                    class="mb-4"
                    @change="getdatapackage(packageTypeSelected)"
                  >
                    <v-tab href="#M">{{ $t("packagenew.month") }}</v-tab>
                    <v-tab href="#Y">{{ $t("packagenew.year") }}</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="packageTypeSelected">
                    <v-tab-item
                      v-for="i in ['M','Y']"
                      :key="i"
                      :value="i"
                    >
                      <v-card 
                        id="borderradius"
                        outlined
                        class="mb-4"
                        v-for="item in listpackage"
                        :key="item.id"
                        :class="item.id === activeIndex ? 'primary' : 'lighten-1'"
                      >
                        <v-layout justify-left class="pt-2">
                          <v-card-title 
                            class="text-h5"
                            :style="item.id === activeIndex ? price_dark : $vuetify.theme.dark ? price_dark : price_"
                          >
                            {{ numberWithCommas(item.package_price) }}
                          </v-card-title>
                            <p 
                              class="text-center mt-6" 
                              :style="item.id === activeIndex ? 'fornt-size: 25; color: #FFFFFF;' : 'fornt-size: 25; color: #000000;'" 
                              v-if="packageTypeSelected === 'M'"
                            >
                              {{ $t("packagenew.baht_month") }}
                            </p>
                            <p 
                              class="text-center mt-6" 
                              :style="item.id === activeIndex ? 'fornt-size: 25; color: #FFFFFF;' : 'fornt-size: 25; color: #000000;'" 
                              v-else
                            >
                              {{ $t("packagenew.baht_year") }}
                            </p>
                        </v-layout>
                        <v-expansion-panels 
                          flat
                          :dark="item.id === activeIndex ? true : false"
                        >
                          <v-expansion-panel style="background-color: #00000000">
                            <v-expansion-panel-header :color="item.id === activeIndex ? 'primary' : 'lighten-1'">
                              <v-layout justify-left>
                                <span class="text-left pr-4" style="fornt-size: 14">{{ item.package_name }}</span>
                                <span
                                  :style="item.id === activeIndex ? 'color: #FFFFFF;' : 'color: #1279BC;'"
                                >
                                  {{ item.package_storage }} 
                                </span>
                              </v-layout>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content 
                              :color="item.id === activeIndex ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" 
                              class="#FFFFFF" 
                              :dark="item.id === activeIndex ? true : false"
                            >
                              <v-list 
                                flat 
                                elevation="0" 
                                :color="item.id === activeIndex ? '#1279bc' : $vuetify.theme.dark ? '#363636' : '#FAFAFA'" 
                                class="#FFFFFF ml-n4" 
                                :dark="item.id === activeIndex ? true : false"
                              >
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Manage User</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>cloud_download</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title>Upload/Download/Share</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>accessibility</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Restrict Access</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>find_in_page</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Advance Search</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Connect Through API</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense>
                                  <v-list-item-icon><v-icon>settings</v-icon></v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title> Send File to e-Mail</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                        <v-flex lg12 xs12 class="mr-4 ml-4">
                        </v-flex>
                        <v-flex lg12 xs12 class="mr-4 ml-4 ma-4">
                          <v-btn block depressed color="#5AB685" dark @click="$router.push('/')">
                            {{ $t("packagenew.login_for_purchase_package") }}
                          </v-btn>
                        </v-flex>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <p class="font-weight-regular mt-2 ml-2" style="color:red;">
                    {{ $t("packagenew.vat") }}
                  </p>
                </v-sheet>
              </v-flex>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        </div>
      <!-- </v-img> -->
    </section>
  </v-app>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
const dialogoneid = () =>
  import("../components/optional/dialog-dialogoneid.vue");
const dialogpolicyoneid = () =>
  import("../components/optional/dialog-dialogpolicyoneid.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  validations: { 
     storage_citizen: { required },
  },
  mixins: [validationMixin],
  components: {
    dialogoneid,
    dialogpolicyoneid,
  },
  data: function() {
    return {
      listpackage_addon_show_Update: [],
      activeIndex: null,
      listpackage: [],
      model: null,
      type_package: "personal",
      price_storage_citizen: 35,
      storage_citizen: 10,
      packageTypeSelected: "M",
      valid: "",
      menu: false,
      defaultflag: "",
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en",
        },
        {
          flag: "th",
          name: "languages.thai",
          data: "th",
        },
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  // computed: {
  //   resolutionScreen() {
  //     switch (this.$vuetify.breakpoint.name) {
  //       case "xs":
  //         return 220;
  //       case "sm":
  //         return 400;
  //       case "md":
  //         return 500;
  //       case "lg":
  //         return 600;
  //       case "xl":
  //         return 800;
  //     }
  //   },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "storage_usage"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headergb() {
      return "font-size: 40px";
    },
    header() {
      return "font-size: 30px";
    },
    price_() {
      return "color:black;" + "font-weight: 600; font-size:28px;";
    },
    price_dark() {
      return "color:white;" + "font-weight: 600; font-size:28px;";
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    darkModeText() {
      return this.$vuetify.theme.dark ? "color: white;" : "color: black" + ";" + "font-size:20px;"
    },
  },
    // container_myfile () { // ไว้ใช้กำหนดความสูง card
    //   if(this.resolutionScreen >= 500){
    //     return this.show_menumanage ? this.changeUI ? 'thiscontainer_myfile' : 'thiscontainer_myfile_with_header' : 'thiscontainer_myfile'
    //   } else {
    //     return 'thiscontainer_myfile'
    //   }
    // },
  methods: {
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
    fn_tolowercase() {
      this.username = this.username.toLowerCase();
    },
     numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    fn_minus_package(price) {
      this.storage_citizen = parseInt(price -= 10)
      this.price_storage_citizen = parseInt(price) * 3.5;
      // if(this.packageTypeSelected === 'M'){
      //   this.price_storage_citizen = parseInt(price) * 3.5;
      // }else{
      //   this.price_storage_citizen = parseInt(price) * 3.5 *12;
      // }
      console.log(typeof this.price_storage_citizen);
       console.log("this.price_storage_citizen",this.price_storage_citizen);
      console.log("this.price_storage_citizen", this.price_storage_citizen);
    },
    fn_plus_package(price) {
      console.log(typeof price);
      this.storage_citizen = parseInt(price += 10)
      this.price_storage_citizen = parseInt(price) * 3.5;
      // if(this.packageTypeSelected === 'M'){
      //   this.price_storage_citizen = parseInt(price) * 3.5;
      // }else{
      //   this.price_storage_citizen = parseInt(price) * 3.5 *12;
      // }
      console.log(typeof this.price_storage_citizen);
       console.log("this.price_storage_citizen",this.price_storage_citizen);
      console.log("this.price_storage_citizen", this.price_storage_citizen);
    },
    async getdatapackage(type) {
      this.loaddataprogress = true;
      this.listpackage = [];
      this.listpackage_addon = [];
      this.listpackage_addon_ = [];
      this.list_selected_package_main_update = [];
      let payload;
      if (type === "M") {
        payload = {
          limit: "10",
          offset: "0",
          period: "M",
          data_type: this.dataAccountActive.type === 'Citizen' ? 'citizen' : 'business'
        };
      } else {
        payload = {
          limit: "10",
          offset: "0",
          period: "Y",
          data_type: this.dataAccountActive.type === 'Citizen' ? 'citizen' : 'business'
        };
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + `/api/get_package`, payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          // console.log("res==========", response);
          // console.log("id_mmm", this.id_mypackage);
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            for (var i = 0; i < response.data.package_main.length; i++) {
              let datapackage = {};
              datapackage["cre_dtm"] = response.data.package_main[i].cre_dtm;
              datapackage["feature_extension"] = response.data.package_main[i].feature_extension;
              datapackage["id"] = response.data.package_main[i].id;
              datapackage["package_name"] = response.data.package_main[i].package_name;
              datapackage["package_name_th"] = response.data.package_main[i].package_name_th;
              datapackage["package_price"] = response.data.package_main[i].package_price;
              datapackage["package_storage"] = response.data.package_main[i].package_storage;
              datapackage["package_number_user"] = response.data.package_main[i].number_user;
              this.listpackage.push(datapackage);
              this.list_selected_package_main_update.push({ package_id: response.data.package_main[i].id, quantity: 0 });
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon = {};
              datapackage_addon["cre_dtm"] = response.data.package_addon[i].cre_dtm;
              datapackage_addon["feature_extension"] = response.data.package_addon[i].feature_extension;
              datapackage_addon["id"] = response.data.package_addon[i].id;
              datapackage_addon["package_name"] = response.data.package_addon[i].package_name;
              datapackage_addon["package_name_th"] = response.data.package_addon[i].package_name_th;
              datapackage_addon["package_price"] = response.data.package_addon[i].package_price;
              datapackage_addon["package_storage"] = response.data.package_addon[i].package_storage;
              datapackage_addon["package_number_user"] = response.data.package_addon[i].number_user;
              datapackage_addon["package_num_package"] = 0;
              datapackage_addon["package_price_sum"] = 0;

              this.listpackage_addon.push(datapackage_addon);
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon_ = {};
              datapackage_addon_["package_id"] = response.data.package_addon[i].id;
              datapackage_addon_["quantity"] = 0;
              this.listpackage_addon_.push(datapackage_addon_);
            }

            for (var i = 0; i < response.data.package_addon.length; i++) {
              let datapackage_addon_sowupdate = {};
              datapackage_addon_sowupdate["cre_dtm"] = response.data.package_addon[i].cre_dtm;
              datapackage_addon_sowupdate["feature_extension"] = response.data.package_addon[i].feature_extension;
              datapackage_addon_sowupdate["id"] = response.data.package_addon[i].id;
              datapackage_addon_sowupdate["package_name"] = response.data.package_addon[i].package_name;
              datapackage_addon_sowupdate["package_name_th"] = response.data.package_addon[i].package_name_th;
              datapackage_addon_sowupdate["package_price"] = response.data.package_addon[i].package_price;
              datapackage_addon_sowupdate["package_storage"] = response.data.package_addon[i].package_storage;
              datapackage_addon_sowupdate["package_number_user"] = response.data.package_addon[i].number_user;
              datapackage_addon_sowupdate["package_num_package"] = 0;
              datapackage_addon_sowupdate["package_price_sum"] = 0;
              datapackage_addon_sowupdate["quantity"] = 0;

              this.listpackage_addon_show_Update.push(datapackage_addon_sowupdate);
            }

            // console.log("listpackage", this.listpackage);
            // console.log("listpackage_addon", this.listpackage_addon);
            // console.log("listpackage_addon_", this.listpackage_addon_);
            // console.log("list_selected_package_main_update", this.list_selected_package_main_update);

            // this.fn_checkpackage();
          } else {
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    
  },
  created() {
    this.changelang(localStorage.getItem("lang"));
  },
  mounted(){
    this.getdatapackage(this.packageTypeSelected);
  },
};
</script>
<style scoped>
/* .bgregister {
  background: url('../assets/img/background_register_full.png');
  background-size: cover;
  height: auto;
} */
</style>
